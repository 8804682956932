import PersonalLoanProduct from "../product-models/PersonalLoanProduct"
import Feature from "../Feature"

let obj: any = null
export default class PersonalLoanFeature extends Feature {

  // = CUSTOM METHODS

  public getAutoCarLoanDescription(): string {
    let minRate = Number.MAX_VALUE
    let minRateComparisonRate = null
    let minLoanAmount = Number.MAX_VALUE
    let maxLoanAmount = Number.MIN_VALUE

    Object.keys(this.products).forEach(productId => {
      const product = this.products[productId] as PersonalLoanProduct
      if (product.carLoan === "X") {
        // skip products not eligible as car loans
        return
      }
      Object.keys(product.lendingRates).forEach(rateId => {
        const rate = product.lendingRates[rateId]
        if (rate.minRate < minRate) {
          minRate = rate.minRate
          minRateComparisonRate = rate.minComparisonRate
        }
        if (rate.minLoanAmount < minLoanAmount) {
          minLoanAmount = rate.minLoanAmount
        }
        if (rate.maxLoanAmount && rate.maxLoanAmount > maxLoanAmount) {
          maxLoanAmount = rate.maxLoanAmount
        }
      })
    })

    const autoDescription = `Compare ${this.name} Car Loans from across the market, including all-purpose Personal Loans. Rates from ${(minRate*100).toFixed(2)}% p.a.` + (minRateComparisonRate ? ` (${(minRateComparisonRate*100).toFixed(2)}% p.a. comparison rate*).` : "") + ` Loans from $${minLoanAmount.toLocaleString()} up to $${maxLoanAmount.toLocaleString()}. `
    
    return autoDescription
  }

  // = ABSTRACT OVERRIDES

  // TODO: remove this?
  public getFilterAmounts(): { [id: string]: number } {
    // filterAmounts
    const filterAmounts: { [id: string]: number } = {
      // TODO: define filter amounts
      "Secured Loan": 0,
      "Unsecured Loan": 0,
      "Fixed": 0,
      "Variable": 0,
      "Major Banks": 0,
      "Challengers": 0,
      "Cash Back": 0,
      "Discounted Rate": 0
    }

    Object.keys(this.products).forEach((productId: string) => {
      const product = this.products[productId] as PersonalLoanProduct
      const productFilterAmounts = product.getFilterAmounts()
      Object.keys(productFilterAmounts).forEach(id => {
        if (filterAmounts[id] !== undefined) {
          filterAmounts[id] += productFilterAmounts[id]
        } else {
          filterAmounts[id] = productFilterAmounts[id]
        }
      })
    })

    return filterAmounts
  }

  public getTitle(): string {
    return `Top ${this.name} Personal Loans Australia | Stay or Go`
  }

  public getAutoDescription(): string {
    let minRate = Number.MAX_VALUE
    let minRateComparisonRate = null
    let minLoanAmount = Number.MAX_VALUE
    let maxLoanAmount = Number.MIN_VALUE

    Object.keys(this.products).forEach(productId => {
      const product = this.products[productId] as PersonalLoanProduct
      if (product.carLoan === "Y") {
        // exclude car loan products
        return
      }
      Object.keys(product.lendingRates).forEach(rateId => {
        const rate = product.lendingRates[rateId]
        if (rate.minRate < minRate) {
          minRate = rate.minRate
          minRateComparisonRate = rate.minComparisonRate
        }
        if (rate.minLoanAmount < minLoanAmount) {
          minLoanAmount = rate.minLoanAmount
        }
        if (rate.maxLoanAmount && rate.maxLoanAmount > maxLoanAmount) {
          maxLoanAmount = rate.maxLoanAmount
        }
      })
    })

    const autoDescription = `Compare ${this.name} Personal Loans from across the market. Rates from ${(minRate*100).toFixed(2)}% p.a.` + (minRateComparisonRate ? ` (${(minRateComparisonRate*100).toFixed(2)}% p.a. comparison rate*).` : "") + ` Loans from $${minLoanAmount.toLocaleString()} up to $${maxLoanAmount.toLocaleString()}. `
    
    return autoDescription
  }

  /**
   * Returns a list of components which render product information
   *
   * @param {((product: Product, key: number, isLast?: boolean | undefined, offerFilter?: string) => JSX.Element)} makeProductComponent - The function specifying how to render a product component
   * @param {(headerName: string) => JSX.Element} makeCategoryHeader - The function specifying how to render a category header
   * @param {(a: Product, b: Product) => number} [customSortMethod] - (Optional) A function to compare two products for sorting
   * @param {string[]} [productFilterIds] - (Optional) A list of product ids to filter on
   * @return {JSX.Element[]} The list of rendered product components
   * @memberof Feature
   */
   public getProductList(
    makeProductComponent: (product: PersonalLoanProduct, key: number, isLast?: boolean | undefined, offerFilter?: string) => JSX.Element,
    makeCategoryHeader: (headerName: string) => JSX.Element,
    customSortMethod?: (a: PersonalLoanProduct, b: PersonalLoanProduct) => number,
    productFilterIds?: string[]
  ): JSX.Element[] {
    const productList: JSX.Element[] = []

    if (!customSortMethod) {
      // use default custom sorting method and include category headers
      // Categorise by providers
      const productsByProviders: { [id: string]: PersonalLoanProduct[] } = {}
      Object.keys(this.products).forEach((productId: string) => {
        if (!productFilterIds || productFilterIds.includes(productId)) {
          const productProvider: string = this.products[productId].providerName

          if (productProvider in productsByProviders) {
            productsByProviders[productProvider].push(this.products[productId] as PersonalLoanProduct)
          } else {
            productsByProviders[productProvider] = [this.products[productId] as PersonalLoanProduct]
          }
        }
      })

      // Fill product list
      Object.keys(productsByProviders).forEach(providerName => {
        const products: PersonalLoanProduct[] = productsByProviders[providerName]

        // Add Provider Header to list
        if (products.length > 0) {
          productList.push(makeCategoryHeader(providerName))
        }

        // Add Product Components to list
        const sortedProducts = products.sort((a,b) => this.compare(a,b))
        sortedProducts.forEach((product: any, key: number) => {
          productList.push(makeProductComponent(product, key, key === products.length - 1, this.name))
        })
      })
    } else {
      // use custom sorting method, don't include category headers
      const filteredProducts: PersonalLoanProduct[] = []
      if (productFilterIds) {
        productFilterIds.forEach(id => {
          if (Object.keys(this.products).includes(id)) {
            filteredProducts.push(this.products[id] as PersonalLoanProduct)
          }
        })
      } else {
        Object.values(this.products).forEach(p => filteredProducts.push(p as PersonalLoanProduct))
      }

      const sortedProducts = filteredProducts.sort((a,b) => {
        // use provider id then default sort as tie-breaker
        const customDiff = customSortMethod(a,b)
        const providerDiff = Number(a.providerId) - Number(b.providerId)
        return (customDiff !== 0 ? customDiff : (
          providerDiff !== 0 ? providerDiff : this.compare(a,b)
        ))
      })
      sortedProducts.forEach((product: any, key: number) => {
        productList.push(makeProductComponent(product, key, key === sortedProducts.length - 1, this.name))
      })
    }

    return productList
  }

  // = AUTOMATED FUNCTIONS =
  public static Parse(d: string): PersonalLoanFeature {
    return PersonalLoanFeature.Create(JSON.parse(d))
  }
  public static Create(d: any, field: string = "root"): PersonalLoanFeature {
    if (!field) {
      obj = d
      field = "root"
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== "object") {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.name, false, field + ".name")
    checkString(d.urlSlug, true, field + ".urlSlug")
    d.nameFormatted = d.name
    d.heading = d.name
    d.products = {}

    return new PersonalLoanFeature(d)
  }
  public static Initialise(d: any): PersonalLoanFeature {
    return new PersonalLoanFeature(d)
  }
  private constructor(d: any) {
    super(d)
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, "non-nullable object", false)
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "string" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "string", nullable)
  }
}
function errorHelper(field: string, d: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ", null, or undefined"
  }
  throw new TypeError("Expected " + type + " at " + field + " but found:\n" + JSON.stringify(d) + "\n\nFull object:\n" + JSON.stringify(obj))
}
