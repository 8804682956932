import Product from "./Product"
export default abstract class Provider {
  public readonly MODEL_CATEGORY: string = "provider"

  public readonly id: number
  public readonly name: string
  public readonly nameFormatted: string
  public readonly urlSlug: string
  public readonly heading: string
  public readonly popular: boolean
  public readonly big4: boolean
  public readonly participant: boolean
  public readonly products: { [id: string]: Product }
  public readonly productsByRewards: { [id: string]: Product[] }
  public readonly autoDescription: string
  public readonly filterAmounts: { [id: string]: number }

  // = CUSTOM FUNCTIONS =
  public getProductById(id: number): Product {
    return this.products[id.toString()]
  }

  // TODO: remove this?
  public getFilterAmounts(): { [id: string]: number } {
    return this.filterAmounts
  }

  // Sort products, with GRANDFATHERED and PAUSED products at the bottom
  protected compare(a: Product, b: Product): number {
    const order: { [id: string]: number } = { Y: 1, GRANDFATHERED: 2, PAUSED: 3 }
    const orderA = order[a.forSale]
    const orderB = order[b.forSale]
    const hasOfferA = Object.values(a.offers).some(o => o.active === "Y" && o.landingPageUrl)
    const hasOfferB = Object.values(b.offers).some(o => o.active === "Y" && o.landingPageUrl)
    
    if (orderA > orderB) {
      return 1
    } else if (orderB > orderA) {
      return -1
    }
    
    if (hasOfferA && !hasOfferB) {
      return -1
    } else if (hasOfferB && !hasOfferA) {
      return 1
    }

    return Number(a.id) - Number(b.id)
  }

  // = ABSTRACT METHODS =
  public abstract getAutoDescription(): string
  // Alternative Products
  public abstract getAlternateProductsByProduct(product: Product): Product[]
  public abstract getUnfilteredProductsByProduct(product: Product): Product[]
  public abstract getAlternativeProductList(product: Product, makeProductComponent: (product: Product, key: number, isLast?: boolean | undefined) => JSX.Element): JSX.Element[]
  
  // = CONSTRUCTOR =
  protected constructor(d: any) {
    this.id = d.id
    this.name = d.name
    this.nameFormatted = d.nameFormatted
    this.urlSlug = d.urlSlug
    this.heading = d.name
    this.popular = d.popular
    this.big4 = d.big4
    this.participant = d.participant
    this.products = d.products
    this.productsByRewards = d.productsByRewards
    this.autoDescription = d.autoDescription
    this.filterAmounts = d.filterAmounts
  }
}
