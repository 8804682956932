// extracted by mini-css-extract-plugin
export var button = "style-module--button--0b467";
export var cardImage = "style-module--card-image--c96fd";
export var cardImageImg = "style-module--card-image-img--f1493";
export var cardRow = "style-module--card-row--39e80";
export var cardValuesHeader = "style-module--card-values-header--f393c";
export var cardValuesHeaderMobile = "style-module--card-values-header-mobile--62352";
export var cardValuesHeaderTablet = "style-module--card-values-header-tablet--8a2b4";
export var description = "style-module--description--4426d";
export var dropdown = "style-module--dropdown--f120b";
export var heading = "style-module--heading--83109";
export var innerProductComponent = "style-module--inner-product-component--6c513";
export var listHeader = "style-module--list-header--e5d0b";
export var listHeaderMobile = "style-module--list-header-mobile--acb07";
export var productComponent = "style-module--product-component--627ab";
export var rightContainer = "style-module--right-container--83b04";