import { CircularProgress, useMediaQuery } from "@mui/material"
import PersonalLoanProduct from "models/product-models/PersonalLoanProduct"
import Provider from "models/Provider"
import Reward from "models/Reward"
import React, { useEffect, useState } from "react"
import * as styles from "./style.module.scss"
import { Typography } from "components/typography"
import ProductComponent from "components/content/personal-loans/product-component"
import { ListHeaderProvider } from "components/content/list-header"
import { JsonLd } from "react-schemaorg"
import { BreadcrumbList } from "schema-dts"
import PersonalLoanFeature from "models/feature-models/PersonalLoanFeature"
import PersonalLoanProvider from "models/provider-models/PersonalLoanProvider"
import Product from "models/Product"
import PersonalLoanOffer from "models/offer-models/PersonalLoanOffer"
// import { CardAds } from "components/content/card-ads";
import ReturnLinkChain from "components/content/return-link-chain"
import Layout from "components/layout"
import Sort from "components/content/sort"
import FilterMenu from "components/content/filter-menu"
import * as ProductValuesHeader from "components/content/product-values-header"
import Markdown from "components/markdown"
import { compileSchemaOrgQa } from "components/schema"
import Feature from "models/Feature"
import LoanFilterBar from "components/content/personal-loans/input-filter"
import { PersonalLoanDisclaimer } from "components/content/content-disclaimer"
import Pagination from "components/pagination"
import AppManager from "services/app-manager"
import Faq from "components/content/faq"
import FactCheckedDialog from "components/content/fact-checked-dialog"

const ProviderTemplate = (props: any) => {
  const { categoryJson, dateUpdated, ads, qa, plSubcategory }: { categoryJson: any; dateUpdated: string; ads: any; qa?: any; plSubcategory?: string } = JSON.parse(props.pageContext.p)
  const isTablet = !useMediaQuery("(min-width:1024px)")
  const isWide = useMediaQuery("(min-width:768px)")
  const [filter, setFilter] = useState<string[]>([])
  const [sort, setOnlySort] = useState("Interest Rate")
  const [tempFilter, setTempFilter] = useState<string[]>([])
  const [linkFirst, setLinkFirst] = useState(true)
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = dateUpdated.substring(8, 10) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)
  const [page, setPage] = useState(0)
  const [offersPerPage, setOffersPerPage] = useState(10)
  const [isLoading, setLoading] = useState(true)

  const updateFilter = (v: string[]) => {
    setPage(0)
    setFilter(v)
  }

  const updateOnlySort = (v: string) => {
    setPage(0)
    setOnlySort(v)
  }

  const updateTempFilter = (v: string[]) => {
    setPage(0)
    setTempFilter(v)
  }

  function makeProductComponent(product: Product, key: number, isLast?: boolean, offerFilter?: string) {
    const plProduct = PersonalLoanProduct.Initialise(product)
    const prodTerms = plProduct.getAvailableLoanTerms()
    // switch to defaults if loan size/term are 0
    const useLoanSize = loanAmount !== 0 ? loanAmount : Math.max(Math.min(plProduct.maxLoanAmount ? plProduct.maxLoanAmount : 100000, 30000), plProduct.minLoanAmount)
    const useLoanTerm = termLength !== 0 ? termLength : prodTerms.length > 0 ? (prodTerms.includes(5) ? 5 : prodTerms[Math.floor((prodTerms.length - 1) / 2)]) : 0
    return (
      <div key={product.id.toString() + "-" + key.toString()} style={{ borderBottom: isLast ? "" : "0.5pt #D2D2D2 solid" }} className={styles.productComponent}>
        <div className={styles.innerProductComponent}>
          <ProductComponent loanSize={useLoanSize} termLength={useLoanTerm} product={PersonalLoanProduct.Initialise(product)} learnMore offerFilter={offerFilter} isTablet={isTablet} />
        </div>
      </div>
    )
  }

  function isProductComponent(component: JSX.Element) {
    return component.props.className && component.props.className === styles.productComponent
  }

  function makeCategoryHeader(headerName: string) {
    return (
      <ListHeaderProvider key={headerName} isMobile={!isWide || isTablet}>
        {headerName}
      </ListHeaderProvider>
    )
  }

  function setSort(name: string) {
    setLoading(true)
    updateOnlySort(name)
  }

  function makeSchemaOrgProvider(data: Provider): JSX.Element {
    return (
      <div>
        {/* <JsonLd<BreadcrumbList>
          item={{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Personal Loans",
                item: "https://www.stayorgo.com.au/personal-loans",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: data.name,
                item: "https://www.stayorgo.com.au/" + data.name.toLowerCase().replace(/ /g, "-") + "-credit-cards",
              },
            ],
          }}
        />
        <JsonLd<BankOrCreditUnion>
          item={{
            "@context": "https://schema.org",
            "@type": "BankOrCreditUnion",
            brand: data.name,
            name: data.name,
            // logo: providerImage(data.id),
            // url: "https://www.stayorgo.com.au",
            location: "Australia",
            // description: data.getAutoDescription(),
          }}
        /> */}
      </div>
    )
  }

  let category: PersonalLoanProvider | PersonalLoanFeature | null = null
  switch (categoryJson.MODEL_CATEGORY) {
    case "provider":
      category = PersonalLoanProvider.Initialise(categoryJson)
      break
    case "feature":
    default:
      category = PersonalLoanFeature.Initialise(categoryJson)
      break
  }

  // filter products
  let categoryProducts = { ...category.products }
  let filteredProducts: PersonalLoanProduct[] = []
  const filteredProductIds: string[] = []

  // check if not specific loan type
  Object.keys(categoryProducts).forEach(productId => {
    const product: PersonalLoanProduct = PersonalLoanProduct.Initialise(categoryProducts[productId])
    // check if car loan, remove if not car loan provider page
    if (product.carLoan === "Y" && plSubcategory !== "cl") {
      delete categoryProducts[productId]
      return
    }
    // check if excluded from car loans
    if (product.carLoan === "X" && plSubcategory === "cl") {
      delete categoryProducts[productId]
      return
    }
  })

  // figure out default loan size and term
  let defaultMinLoanSize: number = Number.MAX_VALUE
  let defaultMaxLoanSize: number = 0
  const availableLoanTerms: number[] = []

  Object.keys(categoryProducts).forEach(productId => {
    const product: PersonalLoanProduct = PersonalLoanProduct.Initialise(categoryProducts[productId])
    defaultMinLoanSize = Math.min(defaultMinLoanSize, product.minLoanAmount)
    defaultMaxLoanSize = Math.max(defaultMinLoanSize, defaultMaxLoanSize, product.maxLoanAmount ? product.maxLoanAmount : defaultMaxLoanSize)
    product.getAvailableLoanTerms().forEach(term => {
      if (!availableLoanTerms.includes(term)) {
        availableLoanTerms.push(term)
      }
    })
  })

  availableLoanTerms.sort()
  const defaultLoanTerm = availableLoanTerms.length > 0 ? (availableLoanTerms.includes(5) ? 5 : availableLoanTerms[Math.floor((availableLoanTerms.length - 1) / 2)]) : 0

  defaultMaxLoanSize = defaultMaxLoanSize === defaultMinLoanSize ? 100000 : defaultMaxLoanSize
  let defaultLoanSize = Math.min(Math.max(defaultMinLoanSize, 30000), defaultMaxLoanSize)
  defaultLoanSize = defaultLoanSize === 0 ? 30000 : defaultLoanSize

  const [loanAmount, setLoanSize] = React.useState(defaultLoanSize)
  const [termLength, setLoanTerm] = React.useState(defaultLoanTerm)

  Object.keys(categoryProducts).forEach(productId => {
    const propsProduct: PersonalLoanProduct = PersonalLoanProduct.Initialise(categoryProducts[productId])
    // first also calculate the product's comparison rate and monthly repayment amount
    propsProduct.calculateMonthlyRepayment(loanAmount, termLength)
    const big4 = propsProduct.providerId === "00100" || propsProduct.providerId === "00200" || propsProduct.providerId === "00300" || propsProduct.providerId === "00400"
    // TEMPORARY SOLUTION: need to define this in the Provider/Product model
    const nonBank =
      propsProduct.providerId === "03900" || // Latitude
      propsProduct.providerId === "06390" || // Wisr
      propsProduct.providerId === "05790" || // Symple
      propsProduct.providerId === "04530" || // NOW Finance
      propsProduct.providerId === "04535" || // OurMoneyMarket
      propsProduct.providerId === "05420" || // SocietyOne
      propsProduct.providerId === "00775" // Alex Bank
    let rateFilter: boolean[] = []
    let securityFilter: boolean[] = []
    let providerFilter: boolean[] = []
    let offerFilter: boolean[] = []
    filter.forEach(filterType => {
      switch (filterType) {
        case "Fixed":
          rateFilter.push(propsProduct.fixedRate === "Y")
          break
        case "Variable":
          rateFilter.push(propsProduct.fixedRate !== "Y")
          break
        case "Unsecured":
          securityFilter.push(propsProduct.securedLoan !== "Y")
          break
        case "Secured":
          securityFilter.push(propsProduct.securedLoan === "Y")
          break
        case "Major Banks":
          providerFilter.push(big4 && !nonBank)
          break
        case "Regional & Challengers":
          providerFilter.push(!big4 && !nonBank)
          break
        case "Non-banks":
          providerFilter.push(nonBank)
          break
        // case "Cash Back":
        //   if (Object.keys(propsProduct.offers).length === 0) {
        //     offerFilter.push(false)
        //   } else {
        //     for (let offerId in propsProduct.offers) {
        //       const offer = propsProduct.offers[offerId] as PersonalLoanOffer
        //       offerFilter.push(offer.cashBack !== null)
        //     }
        //   }
        //   break
        // case "Discounted Rate":
        //   if (Object.keys(propsProduct.offers).length === 0) {
        //     offerFilter.push(false)
        //   } else {
        //     for (let offerId in propsProduct.offers) {
        //       const offer = propsProduct.offers[offerId] as PersonalLoanOffer
        //       offerFilter.push(offer.discountedRate !== null)
        //     }
        //   }
        //   break
      }
    })
    if ((rateFilter.includes(true) || rateFilter.length === 0) && (securityFilter.includes(true) || securityFilter.length === 0) && (providerFilter.includes(true) || providerFilter.length === 0) && (offerFilter.includes(true) || offerFilter.length === 0) && (propsProduct.minLoanAmount <= loanAmount || loanAmount === 0) && (propsProduct.maxLoanAmount === null || propsProduct.maxLoanAmount >= loanAmount) && (termLength === 0 || propsProduct.getAvailableLoanTerms().includes(termLength))) {
      filteredProducts.push(propsProduct)
      filteredProductIds.push(productId)
    }
  })

  let sortMethod: ((a: Product, b: Product) => number) | undefined = undefined
  // sort products
  switch (sort) {
    case "Interest Rate":
      sortMethod = (a, b) => {
        const interestRateA = PersonalLoanProduct.Initialise(a).getLowestInterestRateByTerm(termLength)
        const interestRateB = PersonalLoanProduct.Initialise(b).getLowestInterestRateByTerm(termLength)
        if (interestRateA === null) {
          if (interestRateB === null) {
            return 0
          } else {
            return 1
          }
        } else {
          if (interestRateB === null) {
            return -1
          } else {
            return interestRateA - interestRateB
          }
        }
      }
      break
    case "Comparison Rate":
      sortMethod = (a, b) => {
        const compRateA = PersonalLoanProduct.Initialise(a).getLowestInterestComparisonRateByTerm(termLength)
        const compRateB = PersonalLoanProduct.Initialise(b).getLowestInterestComparisonRateByTerm(termLength)
        if (compRateA === null) {
          if (compRateB === null) {
            return 0
          } else {
            return 1
          }
        } else {
          if (compRateB === null) {
            return -1
          } else {
            return compRateA - compRateB
          }
        }
      }
      break
    case "Monthly Repayments":
      sortMethod = (a, b) => {
        const repaymentA = PersonalLoanProduct.Initialise(a).calculateMonthlyRepayment(loanAmount, termLength)
        const repaymentB = PersonalLoanProduct.Initialise(b).calculateMonthlyRepayment(loanAmount, termLength)
        if (repaymentA === null) {
          if (repaymentB === null) {
            return 0
          } else {
            return 1
          }
        } else {
          if (repaymentB === null) {
            return -1
          } else {
            return repaymentA - repaymentB
          }
        }
      }
      break
    case "Link First":
      sortMethod = (a, b) => {
        const productA = PersonalLoanProduct.Initialise(a)
        const productB = PersonalLoanProduct.Initialise(b)
        const hasOfferLinkA = Object.values(productA.offers).some(offer => offer.active === "Y" && offer.landingPageUrl !== null)
        const hasOfferLinkB = Object.values(productB.offers).some(offer => offer.active === "Y" && offer.landingPageUrl !== null)
        if (hasOfferLinkA && !hasOfferLinkB) {
          return -1
        } else if (hasOfferLinkB && !hasOfferLinkA) {
          return 1
        } else {
          return (productA.getLowestInterestRateByTerm(termLength) || 0) - (productB.getLowestInterestRateByTerm(termLength) || 0)
        }
      }
      break
  }
  if (linkFirst) {
    const subsortMethod = sortMethod
    sortMethod = (a, b) => {
      const productA = PersonalLoanProduct.Initialise(a)
      const productB = PersonalLoanProduct.Initialise(b)
      const hasOfferLinkA = Object.values(productA.offers).some(offer => offer.active === "Y" && offer.landingPageUrl !== null)
      const hasOfferLinkB = Object.values(productB.offers).some(offer => offer.active === "Y" && offer.landingPageUrl !== null)
      if (hasOfferLinkA && !hasOfferLinkB) {
        return -1
      } else if (hasOfferLinkB && !hasOfferLinkA) {
        return 1
      } else {
        if (subsortMethod !== undefined) {
          return subsortMethod(a, b)
        } else {
          return (productA.getLowestInterestRateByTerm(termLength) || 0) - (productB.getLowestInterestRateByTerm(termLength) || 0)
        }
      }
    }
  }

  // count filtered products
  const filterAmounts: { [id: string]: number } = {
    Fixed: 0,
    Variable: 0,
    Unsecured: 0,
    Secured: 0,
    "Major Banks": 0,
    "Regional & Challengers": 0,
    "Non-banks": 0,
    // "Cash Back": 0,
    // "Discounted Rate": 0
  }

  let totalAmount: number = filteredProducts.length
  filteredProducts.forEach(p => {
    const productFilterAmounts = p.getFilterAmounts()
    Object.keys(productFilterAmounts).forEach(key => {
      if (filterAmounts[key] !== undefined) {
        filterAmounts[key] += productFilterAmounts[key]
      }
    })
  })

  let productList: JSX.Element[] = category.getProductList(makeProductComponent, makeCategoryHeader, sortMethod, filteredProductIds)

  const productGroups: JSX.Element[][] = []
  productList.forEach(productComponent => {
    if (productComponent) {
      if (isProductComponent(productComponent)) {
        if (productGroups.length === 0) {
          productGroups.push([])
        }
        productGroups[productGroups.length - 1].push(productComponent)
      } else {
        if (sort === "Provider") {
          productGroups.push([productComponent])
        } else {
          productGroups.push([])
        }
      }
    }
  })

  productList = []
  productGroups.forEach(group => {
    if (group.length > 1 || (sort !== "Provider" && group.length > 0)) {
      productList = productList.concat(group)
    }
  })

  if (productList.length === 0) {
    productList.push(<div style={{ textAlign: "center", fontSize: "24px", fontFamily: "Arboria-Book" }}>No Products</div>)
  }

  const sortList: string[] = ["Interest Rate", "Comparison Rate", "Monthly Repayments"]

  const filterList: { [type: string]: string[] } = {
    // "offer": ["Cash Back", "Discounted Rate"]
  }

  if (category.name !== "Secured" && category.name !== "Unsecured") {
    filterList["loan security"] = ["Unsecured", "Secured"]
  }
  filterList["provider"] = ["Major Banks", "Regional & Challengers", "Non-banks"]

  if (category.name !== "Variable Rate" && category.name !== "Fixed Rate") {
    filterList["rate type"] = ["Fixed", "Variable"]
  }

  // Pagination
  const paginatedProductLists: JSX.Element[][] = []

  while (productList.length) {
    paginatedProductLists.push(productList.splice(0, offersPerPage))
  }

  productList = paginatedProductLists[page]

  const currentOfferShown = (offersPerPage * page + 1).toString() + " - " + (offersPerPage * page + paginatedProductLists[page].length).toString()

  const paginationRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (productList.length > 0) {
      setLoading(false)
    }
  }, [isLoading])

  // return (
  //   <div><pre>{JSON.stringify(categoryJson, null, 2)}</pre></div>
  // )

  const autoDescription = plSubcategory === "cl" ? category.getAutoCarLoanDescription() : category.getAutoDescription()

  return (
    <Layout title={category.getTitle()} description={autoDescription} urlSlug={`${category.nameFormatted}-personal-loans`} dateUpdated={dateUpdated}>
      {category instanceof PersonalLoanProvider ? makeSchemaOrgProvider(category) : null}
      {isLoading ? (
        <div style={{ position: "absolute", marginLeft: "50%", marginRight: "50%", marginTop: "30%", zIndex: 99 }}>
          <CircularProgress thickness={6} />
        </div>
      ) : null}
      <div className="page-container" style={{ display: "flex", alignItems: "stretch" }}>
        <div className="content-provider-container" style={{ maxWidth: isTablet ? (isWide ? "648px" : "395px") : "960px" }}>
          {plSubcategory === "cl" ? <ReturnLinkChain linkChain={[{ name: "Car Loans", to: "/car-loans/" }]} currentLocationItem={{ name: category.heading }} excludeBottomMargin={isWide} includeFactCheckedDialog={isWide} factCheckedDialogDateUpdated={dateString} /> : <ReturnLinkChain linkChain={[{ name: "Personal Loans", to: "/personal-loans/" }]} currentLocationItem={{ name: category.heading }} excludeBottomMargin={isWide} includeFactCheckedDialog={isWide} factCheckedDialogDateUpdated={dateString} />}
          <div className={styles.heading}>
            <Typography.H1>
              Compare {category.heading} {plSubcategory === "cl" ? "Car" : "Personal"} Loans
            </Typography.H1>
          </div>
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {autoDescription}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          {/* {ads ? <CardAds ads={ads} /> : null} */}
          <div ref={paginationRef} />
          <div style={{ display: "flex", justifyContent: isWide ? "flex-start" : "center", marginBottom: "30px" }}>
            <LoanFilterBar loanSize={loanAmount} minLoanSize={defaultMinLoanSize} maxLoanSize={defaultMaxLoanSize > defaultMinLoanSize ? defaultMaxLoanSize : undefined} loanTerm={termLength} validLoanTerms={availableLoanTerms} setLoanSize={setLoanSize} setLoanTerm={setLoanTerm} mobile={!isWide} allowAnyAmount allowAnyTerm />
          </div>
          <div className={styles.cardValuesHeader}>
            <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} mobile={true} productValuesHeaderComponent={<ProductValuesHeader.PersonalLoanValuesHeader setSort={setSort} sort={sort} />} />
          </div>
          <div style={{ display: "flex", opacity: isLoading ? "0.4" : "1" }}>
            <div style={{ maxWidth: "648px", width: "100%" }}>
              {!isTablet || !isWide ? <ListHeaderProvider isMobile={!isWide || isTablet}>{sort}</ListHeaderProvider> : null}
              {isTablet && isWide ? (
                <div style={{ display: "flex" }}>
                  <div style={{ width: "337.5px", marginRight: "50px" }}>
                    <div className={styles.cardValuesHeaderTablet}>
                      <div style={{ marginLeft: "19px", marginRight: "19px", display: "flex" }}>
                        <div style={{ height: "100%", width: "100%" }}>
                          <ProductValuesHeader.PersonalLoanValuesHeader forFilter={true} setSort={setSort} sort={sort} />
                        </div>
                      </div>
                    </div>
                    <ListHeaderProvider isMobile>{sort}</ListHeaderProvider>
                    {productList}
                    <Pagination paginatedProductLists={paginatedProductLists} page={page} setPage={setPage} totalAmount={totalAmount} offersPerPage={offersPerPage} scrollRef={paginationRef} />
                  </div>
                  <div style={{ marginTop: "70px" }}>
                    <div style={{ fontSize: "18px", fontFamily: "Arboria-Bold", color: "#4D4D4D", marginBottom: "43px" }}>Total Offers: {totalAmount}</div>
                    <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} linkFirst={linkFirst} setLinkFirst={setLinkFirst} />
                  </div>
                </div>
              ) : (
                <div>
                  {productList}
                  <Pagination paginatedProductLists={paginatedProductLists} page={page} setPage={setPage} totalAmount={totalAmount} offersPerPage={offersPerPage} scrollRef={paginationRef} />
                </div>
              )}
            </div>
            {!isTablet ? (
              <div style={{ marginTop: "10px", marginLeft: "43px" }}>
                <div style={{ fontSize: "18px", fontFamily: "Arboria-Bold", color: "#4D4D4D", marginBottom: "43px" }}>Total Offers: {totalAmount}</div>
                <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} linkFirst={linkFirst} setLinkFirst={setLinkFirst} />
              </div>
            ) : null}
          </div>
          <div style={{ maxWidth: "648px" }}>
            {qa ? (
              <div style={{ marginTop: "60px" }}>
                {/* {compileSchemaOrgQa(qa.qa.qa)} */}
                <ListHeaderProvider>{"Q&A"}</ListHeaderProvider>
                {/* <div style={{ width: "95%", margin: "0 auto 0 auto" }}>
                  <Markdown md={qa.qa.qa} isArticle={true} />
                </div> */}
                <Faq faqContent={qa.qa.qa} />
              </div>
            ) : null}
            <PersonalLoanDisclaimer isDisclaimerOpen isProvider />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProviderTemplate
